import { IconName } from '@fortawesome/fontawesome-svg-core';

export type SubscriptionDefinition = {
  type: '4k' | 'hd' | 'sd';
  price: number;
  days: number;
  icon: IconName;
  features?: string[];
};

const availableSubscriptions: SubscriptionDefinition[] = [
  {
    type: 'sd',
    price: 90,
    days: 1,
    icon: 'computer-classic',
    features: ['Sala konferencyjna w cenie'],
  },
  {
    type: 'hd',
    price: 52.8571428571,
    icon: 'tv-retro',
    days: 7,
  },
  {
    type: '4k',
    price: 33.3333333333,
    icon: 'tv',
    days: 30,
  },
];

export default availableSubscriptions;
